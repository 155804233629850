export const ActivityScroll = () => import('../../components/activity/activityScroll.vue' /* webpackChunkName: "components/activity-scroll" */).then(c => wrapFunctional(c.default || c))
export const BattleBoxDetail = () => import('../../components/battle/BattleBoxDetail.vue' /* webpackChunkName: "components/battle-box-detail" */).then(c => wrapFunctional(c.default || c))
export const BattleBoxItem = () => import('../../components/battle/BattleBoxItem.vue' /* webpackChunkName: "components/battle-box-item" */).then(c => wrapFunctional(c.default || c))
export const BattleCreate = () => import('../../components/battle/BattleCreate.vue' /* webpackChunkName: "components/battle-create" */).then(c => wrapFunctional(c.default || c))
export const BattleCreateConfirm = () => import('../../components/battle/BattleCreateConfirm.vue' /* webpackChunkName: "components/battle-create-confirm" */).then(c => wrapFunctional(c.default || c))
export const BattleEndBystander = () => import('../../components/battle/BattleEndBystander.vue' /* webpackChunkName: "components/battle-end-bystander" */).then(c => wrapFunctional(c.default || c))
export const BattleEndWinner = () => import('../../components/battle/BattleEndWinner.vue' /* webpackChunkName: "components/battle-end-winner" */).then(c => wrapFunctional(c.default || c))
export const BattleJoinRoom = () => import('../../components/battle/BattleJoinRoom.vue' /* webpackChunkName: "components/battle-join-room" */).then(c => wrapFunctional(c.default || c))
export const BattleScroll = () => import('../../components/battle/BattleScroll.vue' /* webpackChunkName: "components/battle-scroll" */).then(c => wrapFunctional(c.default || c))
export const CommonIntroduce = () => import('../../components/common/CommonIntroduce.vue' /* webpackChunkName: "components/common-introduce" */).then(c => wrapFunctional(c.default || c))
export const CommonConfirmDialog = () => import('../../components/common/ConfirmDialog.vue' /* webpackChunkName: "components/common-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomBtn = () => import('../../components/common/CustomBtn.vue' /* webpackChunkName: "components/common-custom-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonDialog = () => import('../../components/common/Dialog.vue' /* webpackChunkName: "components/common-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonNotify = () => import('../../components/common/Notify.vue' /* webpackChunkName: "components/common-notify" */).then(c => wrapFunctional(c.default || c))
export const CommonQRCode = () => import('../../components/common/QRCode.vue' /* webpackChunkName: "components/common-q-r-code" */).then(c => wrapFunctional(c.default || c))
export const EffectsUpgrade = () => import('../../components/effects/upgrade.vue' /* webpackChunkName: "components/effects-upgrade" */).then(c => wrapFunctional(c.default || c))
export const GoodBtnVoice = () => import('../../components/goodCommon/goodBtnVoice.vue' /* webpackChunkName: "components/good-btn-voice" */).then(c => wrapFunctional(c.default || c))
export const GoodCommonVfx = () => import('../../components/goodCommon/goodCommonVfx.vue' /* webpackChunkName: "components/good-common-vfx" */).then(c => wrapFunctional(c.default || c))
export const GoodDetail = () => import('../../components/goodCommon/goodDetail.vue' /* webpackChunkName: "components/good-detail" */).then(c => wrapFunctional(c.default || c))
export const GoodIntroduce = () => import('../../components/goodCommon/goodIntroduce.vue' /* webpackChunkName: "components/good-introduce" */).then(c => wrapFunctional(c.default || c))
export const GoodRechargeSc = () => import('../../components/goodCommon/goodRechargeSc.vue' /* webpackChunkName: "components/good-recharge-sc" */).then(c => wrapFunctional(c.default || c))
export const GoodResult = () => import('../../components/goodCommon/goodResult.vue' /* webpackChunkName: "components/good-result" */).then(c => wrapFunctional(c.default || c))
export const GoodCommonHeaderImg = () => import('../../components/goodCommon/headerImg.vue' /* webpackChunkName: "components/good-common-header-img" */).then(c => wrapFunctional(c.default || c))
export const GoodCommonHistoryTab = () => import('../../components/goodCommon/historyTab.vue' /* webpackChunkName: "components/good-common-history-tab" */).then(c => wrapFunctional(c.default || c))
export const GoodCommonLeftSlideNav = () => import('../../components/goodCommon/leftSlideNav.vue' /* webpackChunkName: "components/good-common-left-slide-nav" */).then(c => wrapFunctional(c.default || c))
export const GoodCommonReturnBox = () => import('../../components/goodCommon/returnBox.vue' /* webpackChunkName: "components/good-common-return-box" */).then(c => wrapFunctional(c.default || c))
export const GoodHomeAnchorLink = () => import('../../components/goodHome/anchorLink.vue' /* webpackChunkName: "components/good-home-anchor-link" */).then(c => wrapFunctional(c.default || c))
export const GoodBanner = () => import('../../components/goodHome/goodBanner.vue' /* webpackChunkName: "components/good-banner" */).then(c => wrapFunctional(c.default || c))
export const GoodHomeBoxList = () => import('../../components/goodHome/goodHomeBoxList.vue' /* webpackChunkName: "components/good-home-box-list" */).then(c => wrapFunctional(c.default || c))
export const GoodHomeTournament = () => import('../../components/goodHome/goodHomeTournament.vue' /* webpackChunkName: "components/good-home-tournament" */).then(c => wrapFunctional(c.default || c))
export const GoodPageFooter = () => import('../../components/goodPage/goodPageFooter.vue' /* webpackChunkName: "components/good-page-footer" */).then(c => wrapFunctional(c.default || c))
export const GoodPageHeader = () => import('../../components/goodPage/goodPageHeader.vue' /* webpackChunkName: "components/good-page-header" */).then(c => wrapFunctional(c.default || c))
export const GoodPageLogin = () => import('../../components/goodPage/goodPageLogin.vue' /* webpackChunkName: "components/good-page-login" */).then(c => wrapFunctional(c.default || c))
export const GoodPageRecharge = () => import('../../components/goodPage/goodPageRecharge.vue' /* webpackChunkName: "components/good-page-recharge" */).then(c => wrapFunctional(c.default || c))
export const GoodPageRegisterSuc = () => import('../../components/goodPage/goodPageRegisterSuc.vue' /* webpackChunkName: "components/good-page-register-suc" */).then(c => wrapFunctional(c.default || c))
export const GoodPageOpenHistory = () => import('../../components/goodPage/openHistory.vue' /* webpackChunkName: "components/good-page-open-history" */).then(c => wrapFunctional(c.default || c))
export const GoodVipBirthday = () => import('../../components/goodVip/goodVipBirthday.vue' /* webpackChunkName: "components/good-vip-birthday" */).then(c => wrapFunctional(c.default || c))
export const GoodVipResult = () => import('../../components/goodVip/goodVipResult.vue' /* webpackChunkName: "components/good-vip-result" */).then(c => wrapFunctional(c.default || c))
export const GoodVipScroll = () => import('../../components/goodVip/goodVipScroll.vue' /* webpackChunkName: "components/good-vip-scroll" */).then(c => wrapFunctional(c.default || c))
export const HelpTabs = () => import('../../components/help/tabs.vue' /* webpackChunkName: "components/help-tabs" */).then(c => wrapFunctional(c.default || c))
export const MineBasicCom = () => import('../../components/mine/MineBasicCom.vue' /* webpackChunkName: "components/mine-basic-com" */).then(c => wrapFunctional(c.default || c))
export const MineCouponCom = () => import('../../components/mine/MineCouponCom.vue' /* webpackChunkName: "components/mine-coupon-com" */).then(c => wrapFunctional(c.default || c))
export const MineDealCom = () => import('../../components/mine/MineDealCom.vue' /* webpackChunkName: "components/mine-deal-com" */).then(c => wrapFunctional(c.default || c))
export const MineInventoryCom = () => import('../../components/mine/MineInventoryCom.vue' /* webpackChunkName: "components/mine-inventory-com" */).then(c => wrapFunctional(c.default || c))
export const MineMailCom = () => import('../../components/mine/MineMailCom.vue' /* webpackChunkName: "components/mine-mail-com" */).then(c => wrapFunctional(c.default || c))
export const MineMainCom = () => import('../../components/mine/MineMainCom.vue' /* webpackChunkName: "components/mine-main-com" */).then(c => wrapFunctional(c.default || c))
export const MineUnpackCom = () => import('../../components/mine/MineUnpackCom.vue' /* webpackChunkName: "components/mine-unpack-com" */).then(c => wrapFunctional(c.default || c))
export const NavRollGoodTime = () => import('../../components/navRoll/rollGoodTime.vue' /* webpackChunkName: "components/nav-roll-good-time" */).then(c => wrapFunctional(c.default || c))
export const NavRollTimeBox = () => import('../../components/navRoll/rollTimeBox.vue' /* webpackChunkName: "components/nav-roll-time-box" */).then(c => wrapFunctional(c.default || c))
export const NavRollRoolRoomDetail = () => import('../../components/navRoll/roolRoomDetail.vue' /* webpackChunkName: "components/nav-roll-rool-room-detail" */).then(c => wrapFunctional(c.default || c))
export const NavUnpackScroll = () => import('../../components/navUnpack/unpackScroll.vue' /* webpackChunkName: "components/nav-unpack-scroll" */).then(c => wrapFunctional(c.default || c))
export const NavUpgradeChooseBox = () => import('../../components/navUpgrade/chooseBox.vue' /* webpackChunkName: "components/nav-upgrade-choose-box" */).then(c => wrapFunctional(c.default || c))
export const NavUpgradeFail = () => import('../../components/navUpgrade/upgradeFail.vue' /* webpackChunkName: "components/nav-upgrade-fail" */).then(c => wrapFunctional(c.default || c))
export const NavUpgradeHistory = () => import('../../components/navUpgrade/upgradeHistory.vue' /* webpackChunkName: "components/nav-upgrade-history" */).then(c => wrapFunctional(c.default || c))
export const NavUpgradeSuccess = () => import('../../components/navUpgrade/upgradeSuccess.vue' /* webpackChunkName: "components/nav-upgrade-success" */).then(c => wrapFunctional(c.default || c))
export const PageIdentification = () => import('../../components/page/PageIdentification.vue' /* webpackChunkName: "components/page-identification" */).then(c => wrapFunctional(c.default || c))
export const PagePassword = () => import('../../components/page/PagePassword.vue' /* webpackChunkName: "components/page-password" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const TestMode = () => import('../../components/testMode/index.vue' /* webpackChunkName: "components/test-mode" */).then(c => wrapFunctional(c.default || c))
export const UnpackOneBox = () => import('../../components/unpack/oneBox.vue' /* webpackChunkName: "components/unpack-one-box" */).then(c => wrapFunctional(c.default || c))
export const UserToolBag = () => import('../../components/userTool/bag.vue' /* webpackChunkName: "components/user-tool-bag" */).then(c => wrapFunctional(c.default || c))
export const UserToolBoxRecord = () => import('../../components/userTool/boxRecord.vue' /* webpackChunkName: "components/user-tool-box-record" */).then(c => wrapFunctional(c.default || c))
export const UserToolCoupon = () => import('../../components/userTool/coupon.vue' /* webpackChunkName: "components/user-tool-coupon" */).then(c => wrapFunctional(c.default || c))
export const UserToolLevelReward = () => import('../../components/userTool/levelReward.vue' /* webpackChunkName: "components/user-tool-level-reward" */).then(c => wrapFunctional(c.default || c))
export const UserToolMessageBar = () => import('../../components/userTool/messageBar.vue' /* webpackChunkName: "components/user-tool-message-bar" */).then(c => wrapFunctional(c.default || c))
export const UserToolMoneyRecord = () => import('../../components/userTool/moneyRecord.vue' /* webpackChunkName: "components/user-tool-money-record" */).then(c => wrapFunctional(c.default || c))
export const UserToolService = () => import('../../components/userTool/service.vue' /* webpackChunkName: "components/user-tool-service" */).then(c => wrapFunctional(c.default || c))
export const UserinfoBindShop = () => import('../../components/userinfo/UserinfoBindShop.vue' /* webpackChunkName: "components/userinfo-bind-shop" */).then(c => wrapFunctional(c.default || c))
export const UserinfoBoxItem = () => import('../../components/userinfo/UserinfoBoxItem.vue' /* webpackChunkName: "components/userinfo-box-item" */).then(c => wrapFunctional(c.default || c))
export const UserinfoCardCom = () => import('../../components/userinfo/UserinfoCardCom.vue' /* webpackChunkName: "components/userinfo-card-com" */).then(c => wrapFunctional(c.default || c))
export const UserinfoDestroy = () => import('../../components/userinfo/UserinfoDestroy.vue' /* webpackChunkName: "components/userinfo-destroy" */).then(c => wrapFunctional(c.default || c))
export const UserinfoDialog = () => import('../../components/userinfo/UserinfoDialog.vue' /* webpackChunkName: "components/userinfo-dialog" */).then(c => wrapFunctional(c.default || c))
export const UserinfoInventoryCom = () => import('../../components/userinfo/UserinfoInventoryCom.vue' /* webpackChunkName: "components/userinfo-inventory-com" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
