/*
 * @Author: your name
 * @Date: 2020-12-22 16:22:51
 * @LastEditTime: 2020-12-28 14:19:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web_ssr\plugins\api.js
 */
export default ({
  app,
  store,
  route,
  redirect,
  env,
  $fetch
}, inject) => {
  inject("getBoxDetails", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/box/get-box-info",
      data: data
    });
  });
  //获取首页箱子 //栏目
  inject("getHomeBox", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-index-column",
      data: data
    });
  });

  inject("getOpenbox", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/box/open-box",
      data: data
    });
  });

  inject("login", data => {
    return $fetch({
      method: "POST",
      url: "/site/email",
      data: data
    });
  });

  inject("steamLogin", data => {
    return $fetch({
      method: "POST",
      url: "/site/steam",
      data: data
    });
  });

  inject("getUserInfo", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/detail",
      data: data
    });
  });

  inject("myGold", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-std-money",
      data: data
    });
  });
  // 滚动箱子
  inject("getmaxPush", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-reward-history",
      data
    });
  });
  //我的背包
  inject("getUserSkins", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/item/get-knap-sack-infos",
      data
    });
  });
  //放弃
  inject("postSell", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/item/convert",
      data
    });
  });
  //领取
  inject("getBack", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/item/retrieve",
      data
    });
  });
  //用户头像修改
  inject("changeUserAvatar", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/change-user-avatar",
      data
    });
  });
  //用户昵称修改
  inject("changeNickName", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/change-nick-name",
      data
    });
  });
  // 取回短信
  inject("rateMsgSwitch", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/set-receipt-sms-switch",
      data
    });
  });
  //获取公告
  inject("getNotice", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-message",
      data
    });
  });

  //首页统计
  inject("userNum", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/statistics/data",
      data
    });
  });

  // 获取所有类别
  inject("getCategory", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/upgrade/get-category-list",
      data
    });
  });
  // 获取升级饰品列表
  inject("getUpgradeList", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/upgrade/get-upgrade-list",
      data
    });
  });
  // 获取饰品图鉴列表
  inject("getOnSaleItem", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/item/get-on-sale-item",
      data
    });
  });
  // 获取升级饰品列表
  inject("upgradeItemHistory", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/upgrade/upgrade-item-history",
      data
    });
  });
  // 获取升级概率信息
  inject("GetUpgradeMinMaxRate", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/upgrade/get-upgrade-min-max-rate",
      data
    });
  });
  // 升级物品
  inject("upgradeItem", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/upgrade/upgrade-item",
      data
    });
  });
  // 获取升级历史
  inject("getUpgradeHistory", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/upgrade/user-upgrade-history",
      data
    });
  });
  // 获取roll房
  inject("getRollRoom", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-roll-rooms",
      data
    });
  });
  // 获取roll房详情
  inject("getRollRoomDetails", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-roll-room-info",
      data
    });
  });
  // 获取ROLL房所有饰品
  inject("getRollRoomItems", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-roll-room-items",
      data
    });
  });
  // 获取ROLL房参与用户
  inject("getRollRoomUsers", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-roll-room-users",
      data
    });
  });
  // 获取ROLL房中奖名单
  inject("getRollRoomRewardUserMenu", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-roll-room-reward",
      data
    });
  });

  // 参与roll房
  inject("joinRollRoom", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/join-roll-room",
      data
    });
  });
  // 获取roll放开奖状态
  inject("getRollRoomStatus", data => {
    return $fetch({
      method: "POST",
      url: "/roll-room/is-open-award",
      data
    });
  });
  // 获取我参与的roll房
  inject("getRollRoomApply", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-self-join-roll-room",
      data
    });
  });
  // 设置steam link
  inject("setSteamLink", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/set-steam-link",
      data
    });
  });
  // 更新steam link
  inject("updateSteamLink", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/update-steam-link",
      data
    });
  });
  // 设置或修改交易密码
  inject("setSteamTradePassword", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/set-steam-trade-password",
      data
    });
  });
  // 获取饰品历史记录
  inject("getHistory", data => {
    return $fetch({
      method: "POST",
      url: "vip/get-history",
      data
    });
  });
  // 获取饰品自动放弃倒计时提示
  inject("getBackpackReminds", data => {
    return $fetch({
      method: "POST",
      url: "vip/backpack-reminds",
      data
    });
  });
  // 自动放弃列表
  inject("getAutoExchangeList", data => {
    return $fetch({
      method: "POST",
      url: "vip/get-auto-exchange-list",
      data
    });
  });
  // 积分商城基础信息
  inject("getPointsBase", data => {
    return $fetch({
      method: "POST",
      url: "score-shop/base",
      data
    });
  });
  // 获取积分饰品商品
  inject("getPointsGoods", data => {
    return $fetch({
      method: "POST",
      url: "score-shop/goods",
      data
    });
  });
  // 放弃饰品
  inject("getPointsId", data => {
    return $fetch({
      method: "POST",
      url: "score-shop/buy-goods",
      data
    });
  });
  // 手机登录
  inject("loginPhone", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/login",
      data
    });
  });

  inject("loginPhoneReqister", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/register",
      data
    });
  });

  // 获取图片验证码
  inject("getCaptcha", data => {
    return $fetch({
      method: "POST",
      url: "/sms/v1/captcha",
      data
    });
  });

  // 获取验证码
  inject("getPhoneCode", data => {
    return $fetch({
      method: "POST",
      url: "/sms/v1/code",
      data
    });
  });

  // 获取最近开箱记录
  inject("getLately", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/box/get-open-box-history",
      data
    });
  });
  // 获取自己开箱记录
  inject("getSelfLately", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/box/get-self-open-box-history",
      data
    });
  });
  // 修改密码
  inject("changePasssword", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/change-user-password",
      data
    });
  });
  // 获取活动
  inject("getActivity", data => {
    return $fetch({
      method: "POST",
      url: "/activity-item-lottery/list",
      data
    });
  });
  // 获取活动详情
  inject("getActivityDetails", data => {
    return $fetch({
      method: "POST",
      url: "/activity-item-lottery/detail",
      data
    });
  });
  //充值金额列表获取
  inject("getMoneyList", data => {
    return $fetch({
      method: "GET",
      url: "/trade/v1/get-recharge-list",
      data
    });
  });

  // 获取优惠券列表
  inject("getCardCoupon", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-card-coupon",
      data
    });
  });
  // 判断是否是首充
  inject("isFirstRecharge", data => {
    return $fetch({
      method: "GET",
      url: "/trade/v1/is-first-recharge",
      data
    });
  });

  //充值金额列表获取
  inject("getPayMode", data => {
    return $fetch({
      method: "GET",
      url: "/trade/v1/get-pay-mode",
      data
    });
  });
  // 发起皮肤充值
  inject("getSkinPay", data => {
    return $fetch({
      method: "POST",
      url: "/trade/v1/create-skin-order",
      data
    });
  });
  // 发起充值
  inject("getRechargePay", data => {
    return $fetch({
      method: "POST",
      url: "/trade/v1/create-coin-order",
      data
    });
  });
  // 获取支付状态
  inject("getRechargeStatus", data => {
    return $fetch({
      method: "GET",
      url: "/trade/v1/pay-status",
      data
    });
  });
  // 提醒发货
  inject("remindTake", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/item/remind-take",
      data
    });
  });
  // 充值记录
  inject("getRechargeList", data => {
    return $fetch({
      method: "POST",
      url: "/recharge/list",
      data
    });
  });
  // 获取福袋
  inject("getLuckyBagStatus", data => {
    return $fetch({
      method: "POST",
      url: "/blessing-bag/has-bag",
      data
    });
  });
  // 领取福袋
  inject("getLuckBagReward", data => {
    return $fetch({
      method: "POST",
      url: "/blessing-bag/get-bag",
      data
    });
  });
  // 红包记录
  inject("getRecordRed", data => {
    return $fetch({
      method: "POST",
      url: "/blessing-bag/red-packet-record",
      data
    });
  });
  // 福袋记录
  inject("getRecordLucky", data => {
    return $fetch({
      method: "POST",
      url: "/blessing-bag/get-record",
      data
    });
  });
  // 获取绑定金额
  inject("getBindCoin", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-bind-coin",
      data
    });
  });
  // 统计支付渠道
  inject("getRechargeManual", data => {
    return $fetch({
      method: "POST",
      url: "/recharge/manual-status",
      data
    });
  });
  // 主播的支付渠道
  inject("anchorQuickRecharge", data => {
    return $fetch({
      method: "POST",
      url: "/trade/v1/anchor-quick-recharge",
      data
    });
  });
  // 绑定邀请码
  inject("bindInviteCode", data => {
    return $fetch({
      method: "GET",
      url: "usercenter/v1/user/bind-invite-code",
      data
    });
  });
  // vip奖励列表
  inject("getVipList", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/get-vip-config",
      data
    });
  });
  // 领取vip昨日流水返利
  inject("getVipYesterdayTurnoverReward", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/get-vip-yesterday-turnover-reward",
      data
    });
  });
  // 统计数据
  inject("getUpgradeData", data => {
    return $fetch({
      method: "POST",
      url: "/upgrade/heartbeat",
      data
    });
  });
  // 身份认证
  inject("getIDCardStatus", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/icard-certification",
      data: data
    });
  });
  // 幸运玩家列表
  inject("getluckyVipList", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-yesterday-winprice",
      data
    });
  });
  // 拉新王列表
  inject("getInviteKingList", data => {
    return $fetch({
      method: "GET",
      url: "/activity/invite-king/list",
      data
    });
  });
  // 新年开箱 - 奖池信息
  inject("getPoolInfo", data => {
    return $fetch({
      method: "POST",
      url: "/jackpot-box/pool-info",
      data
    });
  });
  // 新年开箱 - 中奖箱子
  inject("getBoxList", data => {
    return $fetch({
      method: "GET",
      url: "/front/get-jackpot-box-list",
      data
    });
  });
  // 新年开箱 - 获取奖池爆浆记录
  inject("getRewardLog", data => {
    return $fetch({
      method: "POST",
      url: "/jackpot-box/get-consume-log",
      data
    });
  });
  // 升级-升级记录
  inject("getUpdateList", data => {
    return $fetch({
      method: "GET",
      url: `/upgrade/item-history?${data}`
    });
  });
  // 升级-实时出货记录
  inject("getSucceedList", data => {
    return $fetch({
      method: "GET",
      url: `/upgrade/get-success-list`
    });
  });
  //获取进行中抢卡炼金列表
  inject("getTreasureList", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-treasure-list",
      data
    });
  });
  //获取已结束抢卡炼金列表
  inject("getTreasureEndList", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-end-treasure-list",
      data
    });
  });
  //最近抢卡动态
  inject("getTreasureDynamic", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-treasure-dynamic",
      data
    });
  });
  //抢卡详情页
  inject("getTreasureInfo", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/get-treasure-info",
      data
    });
  });
  //抢卡详情页
  inject("getTreasureEndInfo", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/get-end-treasure-info",
      data
    });
  });
  //参与抢卡
  inject("treasureJoin", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/join-treasure",
      data
    });
  });
  //我的抢卡
  inject("getMyCard", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-self-join-treasure",
      data
    });
  });
  // 口令红包列表
  inject("getCommandList", data => {
    return $fetch({
      method: "POST",
      url: "site/v1/get-recharge-bag-list"
    });
  });
  // 口令红包领取
  inject("getCommandReward", data => {
    return $fetch({
      method: "POST",
      url: "/bag/get",
      data
    });
  });
  // 我的放弃券
  inject("getTreasureCards", data => {
    return $fetch({
      method: "GET",
      url: `/treasure/my-cards?status=${data}`,
      data
    });
  });
  // 放弃券领取奖励
  inject("getTreasureReward", data => {
    return $fetch({
      method: "POST",
      url: `/treasure/get-rewaed`,
      data
    });
  });
  // 获取官方群
  inject("siteGetQQInfo", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/get-qq-code"
    });
  });
  // 注销账号
  inject("destroyAccount", data => {
    return $fetch({
      method: "POST",
      url: "usercenter/v1/user/destroy-user",
      data
    });
  });
  // 获取首页banner
  inject("siteBanner", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-banner",
      data
    });
  });
  //获取服务器时间
  inject("GetServerTime", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-server-time",
    });
  });
  //获取欧皇开箱榜信息
  inject("getEmperorIndex", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-oh-rank-open-box-info",
      data
    });
  });
  //获取欧皇升级榜信息
  inject("GetOhRankUpgradeInfo", data => {
    return $fetch({
      method: "GET",
      url: "site/v1/get-oh-rank-upgradex-info",
      data
    });
  });
  //获取欧皇开箱记录
  inject("getEmperorList", data => {
    return $fetch({
      method: "GET",
      url: "/record/v1/get-oh-open-box-rank",
      data
    });
  });
  //获取欧皇升级记录
  inject("GetOHUpgradeRank", data => {
    return $fetch({
      method: "GET",
      url: "/record/v1/get-oh-upgrade-rank",
      data
    });
  });

  //获取欧皇比武记录
  inject("GetOHPkRank", data => {
    return $fetch({
      method: "GET",
      url: "/record/v1/get-oh-pk-rank",
      data
    });
  });
  //欧皇活动信息
  inject("emperorActiveInfo", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-oh-rank-config-info",
      data
    });
  });
  //获取pk榜欧皇信息
  inject("getEmperorPkList", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-oh-rank-pk-info",
      data
    });
  });
  //欧皇奖励累计
  inject("emperorReward", data => {
    return $fetch({
      method: "GET",
      url: "/emperor/get-reward?" + data,
      data
    });
  });

  // 盒子大战
  // 获取房间列表数据
  inject("getPkRoomList", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/get-room-list",
      data
    });
  });
  
  // 最佳列表
  inject("bestRoomList", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/best-room-list",
      data
    });
  });

  //解散PK房间
  inject("DisbandPkRoom", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/disband-room",
      data
    });
  });
  //创建PK房间
  inject("CreatePkRoom", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/create-room",
      data
    });
  });
  //加入PK房间
  inject("JoinPkRoom", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/join-room",
      data
    });
  });

  //获取PK记录
  inject("GetOwnPkRecord", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/pk/own-record",
      data
    });
  });

  //获取表情列表
  inject("GetPkEmoticon", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/pk/emoticon",
      data
    });
  });
  //发送表情
  inject("SendPkEmoticon", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/emoticon",
      data
    });
  });

  //获取栏目箱子列表信息
  inject("GetIndexColumnBoxes", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-index-column-boxes",
      data
    });
  });
  //获取箱子饰品信息
  inject("GetContainsItem", data => {
    return $fetch({
      method: "GET",
      url: "goods/v1//box/get-contains-item",
      data
    });
  });

  //获取机器人列表
  inject("GetRobotList", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/pk/get-robot-list",
      data
    });
  });
  //获取房间数据
  inject("GetPkRoomData", data => {
    return $fetch({
      method: "GET",
      url: "/goods/v1/pk/get-room-data",
      data
    });
  });
  // 导航菜单
  inject("getNavInfo", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/nav",
      data
    });
  });

  // 热门推荐接口
  inject("getHotRecommendList", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-hot-recommend-list",
      data
    });
  });

  // 获取新闻列表
  inject("getNewList", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-new-list",
      data
    });
  });

  // 获取高光时刻
  inject("getHighlightMoment", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-highlight-moment",
      data
    });
  });

  // 获取用户账变信息
  inject("getAccountChangeRecord", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-account-change-record",
      data
    });
  });

  // 推广用户邀请
  inject("getPromoteUsers", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-promote-users",
      data
    });
  });

  // 推广用户充值记录
  inject("getInviteRecharge", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-invite-recharge",
      data
    });
  });

  // 获取新邮件数量
  inject("getNewMailCount", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-new-mail-count",
      data
    });
  });

  // 获取邮件列表
  inject("getMails", data => {
    return $fetch({
      method: "GET",
      url: "/usercenter/v1/user/get-mails",
      data
    });
  });

  // 标记邮件为已读
  inject("markReadMail", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/mark-read-mail",
      data
    });
  });

  // 领取邮件附件
  inject("receiveMailAppendix", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/receive-mail-appendix",
      data
    });
  });

  // 领取所有邮件附件
  inject("receiveAllMailAppendix", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/receive-all-mail-appendix",
      data
    });
  });

  //获取首页箱子 //栏目
  inject("openBoxKing", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/box/open-box-king",
      data: data
    });
  });

  // 签到页活动
  inject("getSignInfo", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-sign-info",
      data
    });
  });

  // 流水放弃宝石 
  inject("signInExchange", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/sign-in-exchange",
      data
    });
  });


  // 积分放弃活动页
  inject("getTurnoverInfo", data => {
    return $fetch({
      method: "GET",
      url: "/site/v1/get-turnover-info",
      data
    });
  });

  // 积分放弃活动
  inject("turnoverExchange", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/turnover-exchange",
      data
    });
  });

  // 获取每日充值箱子活动详情
  inject("getActPayBoxInfo", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-act-pay-box-info",
      data
    });
  });

  // 获取商品列表
  inject("mallList", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/mall/item-info",
      data
    });
  });

  // 购买商品
  inject("buyItem", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/mall/buy-item",
      data
    });
  });

  // 获取友情链接
  inject("getLinks", data => {
    return $fetch({
      method: "get",
      url: "site/v1/get-links",
      data
    });
  });

  // steam登录
  inject("LoginBySteam", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/login-by-steam",
      data
    });
  });

  // steam回调函数
  inject("SteamOpenidCallback", data => {
    return $fetch({
      method: "POST",
      url: `/usercenter/v1/user/steam-openid-callback`,
      data
    });
  });

  // 世界杯活动详情
  inject("WordCupInfo", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/word-cup-info`,
      data
    });
  });

  // 世界杯参与竞猜
  inject("WordCupBut", data => {
    return $fetch({
      method: "POST",
      url: `/site/v1/word-cup-but`,
      data
    });
  });

  // 积分商城列表
  inject("wordCupShop", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/word-cup-shop`,
      data
    });
  });

  // 商品放弃
  inject("wordCupExchange", data => {
    return $fetch({
      method: "POST",
      url: `/site/v1/word-cup-exchange`,
      data
    });
  });

  // 积分商城
  inject("wordCupOrder", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/word-cup-Order`,
      data
    });
  });

  // 商品放弃物领取
  inject("wordCupGet", data => {
    return $fetch({
      method: "POST",
      url: `/site/v1/word-cup-get`,
      data
    });
  });

  // 获取活动列表
  inject("getActivityList", data => {
    return $fetch({
      method: "GET",
      url: `/site/v1/get-activity-list`,
      data
    });
  });

  // 活动箱子详情
  inject("cardCouponBox", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/activity/card-coupon-box`,
      data
    });
  });

  // 卡券箱子开箱记录
  inject("getOpenEboxHistory", data => {
    return $fetch({
      method: "GET",
      url: `goods/v1/box/get-open-ebox-history`,
      data
    });
  });

  // 获取红包金额
  inject("grabRedPacket", data => {
    return $fetch({
      method: "POST",
      url: `/site/v1/grab-red-packet`,
      data
    });
  });

  // 我的开箱记录
  inject("getSelfOpenEboxHistory", data => {
    return $fetch({
      method: "GET",
      url: `goods/v1/box/get-self-open-ebox-history`,
      data
    });
  });

  // 获取个人开箱记录
  inject("getUserRewardHistory", data => {
    return $fetch({
      method: "POST",
      url: `/goods/v1/item/get-user-reward-history`,
      data
    });
  });

  // 获取饰品图鉴
  inject("getItemMsg", data => {
    return $fetch({
      method: "POST",
      url: `/goods/v1/item/get-item-Msg`,
      data
    });
  });

  // 饰品获取途径
  inject("getItemChannel", data => {
    return $fetch({
      method: "POST",
      url: `/goods/v1/item/get-item-channel`,
      data
    });
  });

  // 获取我的箱子
  inject("getUserBoxList", data => {
    return $fetch({
      method: "GET",
      url: `goods/v1/item/get-user-box-list`,
      data
    });
  });

  // 获取比武战报
  inject("getPkRealTimeReuslt", data => {
    return $fetch({
      method: "GET",
      url: `/goods/v1/pk/real-time-result`,
      data
    });
  });

  // 累计开箱活动
  inject("accumulateOpenBox", data => {
    return $fetch({
      method: "GET",
      url: `/site/v1/activity/accumulate-open-box`,
      data
    });
  });

  // 瓜分88888活动获取列表
  inject("carveUpRankReward", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/activity/carve-up-rank-reward`,
      data
    });
  });

  // 体验箱子
  inject("activityFreeBox", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/activity/free-box`,
      data
    });
  });

  // 首页底部统计数据
  inject("statisticsData", data => {
    return $fetch({
      method: "get",
      url: `/site/v1/statistics/data`,
      data
    });
  });

  // 比武战报记录
  inject("getPkReport", data => {
    return $fetch({
      method: "get",
      url: `/goods/v1/pk/report`,
      data
    });
  });

  // 五一战力排行榜
  inject("getUserPkList", data => {
    return $fetch({
      method: "GET",
      url: `/site/v1/get-user-pk-list`,
      data
    });
  });

  // 五一战力排行榜对局详情
  inject("getActivityOneInfo", data => {
    return $fetch({
      method: "GET",
      url: `/site/v1/pk/get-activity-one-info`,
      data
    });
  });

  // 五一主播团战榜
  inject("getAnchorPkList", data => {
    return $fetch({
      method: "GET",
      url: `/site/v1/get-anchor-pk-list`,
      data
    });
  });

  // 商城绑定
  inject("bindMallAuth", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/bind-mall-auth",
      data: data
    });
  });

  // 商城pop 
  inject("transferItemToMallTask", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/transfer_item_to_mall_task",
      data: data
    });
  });

  // 转盘积分配置
  inject("getIntegralTaskInfo", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-integral-task-info",
      data
    });
  });

  // 领取积分
  inject("receiveIntegral", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/receive-integral",
      data
    });
  });

  // vip等级
  inject("getVipInfo", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get-vip-info",
      data
    });
  });

  // 开箱欧皇记录
  inject("getOpenBoxMaxHistory", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/box/get-open-box-max-history",
      data
    });
  });

  // 领取vip奖励箱子
  inject("getVipReward", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/user/get-vip-reward",
      data
    });
  });

  // 领取vip任务奖励
  inject("getVIpTaskReward", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/user/get-vip-task-reward",
      data
    });
  });

  // 领取生日福利
  inject("getVipBirthdayReward", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/get-vip-birthday-reward",
      data
    });
  });

  // 获取推广等级配置
  inject("getPromotionConf", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/get-promotion-conf",
      data
    });
  });

  // 获取推广任务
  inject("getPromotionTasks", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get-promotion-tasks",
      data
    });
  });

  // 领取推广任务奖励
  inject("receiveAward", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/receive_award",
      data
    });
  });

  // 个人pk记录
  inject("getUserPkReward", data => {
    return $fetch({
      method: "POST",
      url: "/goods/v1/pk/get-user-pk-reward",
      data
    });
  });

  // vip箱子bgm
  inject("getBgmByBoxId", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-bgm-by-box-id",
      data
    });
  });

  // 生成SDK兑换码
  inject("bornSdkCode", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/born_sdk_code",
      data
    });
  });

  // 获取未使用的SDK兑换码
  inject("getSdkCode", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get_sdk_code",
      data
    });
  });

  // SDK兑换码领取金额
  inject("receiveSdkCode", data => {
    return $fetch({
      method: "POST",
      url: "/usercenter/v1/user/receive_sdk_code",
      data
    });
  });

  // 我创建的Roll
  inject("getSelfCreateRoll", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/get-self-create-roll-room",
      data
    });
  });

  // 用户创建Roll房
  inject("createRoll", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/create-roll",
      data
    });
  });

  // 获取未使用的SDK兑换码
  inject("getRollTemplateList", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-roll-template-list",
      data
    });
  });

  // 出货榜
  inject("getItemRank", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-item-rank",
      data
    });
  });

  // 转奖励
  inject("launchBoxSpin", data => {
    return $fetch({
      method: "POST",
      url: "/site/v1/launch-box-spin",
      data
    });
  });

  // 获取本人抽奖信息
  inject("getLaunchBoxCache", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-launch-box-cache",
      data
    });
  });


  // 获取射击游戏配置
  inject("getShootConfig", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/shoot/get-shoot-game-config",
      data
    });
  });

  // 射击箱子
  inject("shootBox", data => {
    return $fetch({
      method: "post",
      url: "/goods/v1/shoot/shoot-box",
      data
    });
  });

  // 获取射击游戏排行榜
  inject("shooGameRank", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/shoot/get-shoot-shipment-rank",
      data
    });
  });

  // 获取射击游戏记录
  inject("shootGameHis", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/shoot/get-shoot-drop-history",
      data
    });
  });

  // 获取系统头像
  inject("getUserAvatarList", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get_user_avatar_list",
      data
    });
  });

  // 修改头像
  inject("changeUserAvatar", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/change-user-avatar",
      data
    });
  });

  // 修改邀请码
  inject("changeUserInviteCode", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/change-user-invite-code",
      data
    });
  });

  // 获取箱子中所有物品
  inject("getGunAllInfo", data => {
    return $fetch({
      method: "get",
      url: "/goods/v1/shoot/get-shoot-box-info",
      data
    });
  });


  // 获取充值计算后的金钱
  inject("getRechargeMoney", data => {
    return $fetch({
      method: "post",
      url: "/trade/v1/get-pre-payment-info",
      data
    });
  });


  // 推广中心信息
  inject("getPromoteInfo", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get-promote-info",
      data
    });
  });

  // 推广中心列表
  inject("getPromoteRebateRecord", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get-promote-rebate-record",
      data
    });
  });

   // 推广中心个人返利列表
   inject("getUserPromoteData", data => {
    return $fetch({
      method: "get",
      url: "/usercenter/v1/user/get-user-promote-data",
      data
    });
  });


  // 领取推广奖励
  inject("receiveRebate", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/receive-rebate",
      data
    });
  });


  // 兑换cdk
  inject("claimCdk", data => {
    return $fetch({
      method: "post",
      url: "/usercenter/v1/user/claim-cdk",
      data
    });
  });

  // 获取活动积分
  inject("getUserIntegral", data => {
    return $fetch({
      method: "get",
      url: "/site/v1/get-user-integral",
      data
    });
  });

  

  // 模拟充值
  inject("MockRecharge", data => {
    return $fetch({
      method: "post",
      url: "/trade/v1/test-mock-recharge",
      data
    });
  });

};
